<template>
  <div>
    <v-row>
      <v-col class="py-2">
        <FlyoutMenuHead
            :selectedItem="getSelectedLocation"
            type="edit"
            route="locations"
        />
      </v-col>
    </v-row>
    <v-divider/>
    <v-row class="mt-4">
      <v-col md="12" class="d-flex align-center justify-space-between pt-4">
        <span class="subtitle text-secondary">General</span>
      </v-col>
    </v-row>
    <v-form
    class="overflow-y-auto scroll-right-md-y scroll-right-lg-y"
    @submit.prevent="editLocation()">
      <v-row>
        <v-col md="8" class="d-flex align-center justify-space-between pb-0">
          <v-text-field
              v-model="location.name"
              v-on:input="changeInput"
              :maxlength="256"
              :counter="256"
              height="40"
              placeholder="Friendly name for your location"
              :rules="[rules.required]"
              dense
              outlined
          >
            <template #label>
              <span class="pl-1 pr-1">Location Name</span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="8">
          <v-text-field
              v-model="location.display_id"
              v-on:input="changeInput"
              :maxlength="256"
              :counter="256"
              height="40"
              placeholder="enter display id for location"
              :rules="[rules.required]"
              dense
              outlined
              :error-messages="getDisplayId"
          >
            <template #label>
              <span class="pl-1 pr-1">Location Display Id</span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="px-1">
        <v-col md="4" class="d-flex align-center justify-space-between pl-0">
          <v-select
              :append-icon="$vuetify.icons.values.chevronDown"
              :items="countries.countries"
              v-on:input="changeInput"
              v-model="location.country_code"
              class="ml-2 mt-n3"
              placeholder="Country"
              :rules="
              this.location.location_type_uuid
                ? [rules.required]
                : []
            "
              outlined
              dense
              height="40"
              item-text="name"
              item-value="code"
              @change="updateSearch()"
          >
            <template #label>
              <span class="pl-1 pr-1">Country</span>
            </template>
          </v-select>
        </v-col>
        <v-col md="4" class="d-flex align-center justify-space-between pr-0">
          <v-text-field
              v-model="location.city"
              v-on:input="changeInput"
              class="mr-2 mt-n3"
              :rules="
              this.location.location_type_uuid 
                ? [rules.required]
                : []
            "
              height="40"
              dense
              outlined
              @change="updateSearch()"
              placeholder="City"
              label="City"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="px-1">
        <v-col md="4" class="d-flex align-center justify-space-between pl-0">
          <v-text-field
              v-model="location.address"
              v-on:input="changeInput"
              class="ml-2 mt-n3"
              :rules="
              this.location.location_type_uuid 
                ? [rules.required]
                : []
            "
              height="40"
              dense
              outlined
              @change="updateSearch()"
              placeholder="Address"
              label=""
          >
          </v-text-field>
        </v-col>
        <v-col md="4" class="d-flex align-center justify-space-between pr-0">
          <v-text-field
              v-model="location.zip_code"
              v-on:input="changeInput"
              class="mr-2 mt-n3"
              height="40"
              :rules="
              this.location.location_type_uuid 
                ? [rules.required]
                : []
            "
              dense
              outlined
              @change="updateSearch()"
              placeholder="Zip Code"
              label=""
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="px-1">
        <v-col md="4" class="d-flex align-center justify-space-between pl-0">
          <v-select
          :append-icon="$vuetify.icons.values.chevronDown"
          :items="timezones"
          v-model="location.timezone"
          class="ml-2 mt-n3"
          placeholder="Timezone"
          :rules="
            this.location.location_type_uuid
              ? [rules.required]
              : []
          "
          outlined
          dense
          height="40"
          item-text="name"
          item-value="code"
          @change="changeInput"
        >
          <template #label>
            <span class="pl-1 pr-1">Timezone</span>
          </template>
        </v-select>
        </v-col>
      </v-row>
      <v-row class="px-1">
        <v-col md="8" class="px-2 py-1">
          <GmapMap
              ref="map"
              v-on:input="changeInput"
              :options="map.options"
              :center="map.center"
              :zoom="map.zoom"
              style="width: 100%; height: 278px; border: solid 1px #dedede; border-radius: 4px;"
          >
            <GmapMarker
              :position="{
              lat: location.latitude
              ? parseFloat(location.latitude)
              : 43.3438,
              lng: location.longitude
              ? parseFloat(location.longitude)
              : 17.8078}"
                :clickable="true"
                :draggable="true"
                @dragend="updateMarker"
            />
          </GmapMap>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="8" class="py-9">
          <v-textarea
              v-on:input="changeInput"
              :maxlength="1000"
              :counter="1000"
              v-model="location.description"
              outlined
              placeholder="Description"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row class="overflow-y-auto mb-12">
        <v-col md="8" class="d-flex align-center justify-space-between">
          <v-autocomplete
              v-model="location.tag_uuids"
              v-on:input="changeInput"
              :items="tags"
              placeholder="Enter tags for your new location"
              dense
              outlined
              height="40px"
              append-icon=""
              @update:search-input="onTagChange"
              :counter="20"
              item-text="name"
              item-value="uuid"
              @keydown.enter="onAddTag"
              :search-input.sync="tagToAdd"
              @input="tagToAdd = null"
              chips
              hint="asd"
              persistent-hint
              maxlength="20"
              deletable-chips
              multiple>
            <template v-slot:message>
              <div class="mt-2 ml-n3 mb-10">
                <span>To add a new hint, type it and press ENTER.</span>
              </div>
            </template>
            <template v-slot:label>
              <span class="pl-1 pr-1">Tags</span>
            </template>
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title class="d-flex align-start justify-space-between">
                  <span class="font-weight-regular">{{ data.item.name }}</span>
                  <span class="font-weight-light">{{ data.item['total_number'] || 0 }} devices and locations</span>
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-slot:no-data>
              <v-list-item-content>
                <v-list-item-title class="d-flex align-start justify-space-between px-4">
                  <span class="font-weight-regular caption">{{ tagToAdd }}</span>
                  <span class="font-weight-light caption">New</span>
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="text-right bottom-navigation-edit py-4 px-2"
       v-bind:style= "[this.$vuetify.theme.dark ? {'background-color': '#1E1E1E'} : {'background-color':'#ffffff'}]"
      >
        <v-col>
          <v-btn
              width="120"
              class="mr-2 font-weight-regular letter-spacing-normal text-capitalize"
              depressed
              text
              @click="closeForm()"
          >{{ $t("common.cancelBtn") }}
          </v-btn>
          <v-btn
              width="120"
              id="btn-blue-disabled"
              class="font-weight-normal letter-spacing-normal text-capitalize"
              color="primary"
              depressed
              :disabled="!changed"
              @click="editLocation()"
          >Save
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import LocationsApi from "../../services/api/Locations";
import FlyoutMenuHead from "../FlyoutMenuHead";
import {gmapApi} from "vue2-google-maps";
import {mapActions, mapGetters} from "vuex";


export default {
  name: "LocationInfoEdit",
  components: {
    FlyoutMenuHead,
  },
  data: () => ({
    loading: false,
    errorDisplayId: "",
    map: {
      pin: {
        position: {
          lat: 0,
          lng: 0,
        },
      },
      center: {
        lat: 43.3438,
        lng: 17.8078,
      },
      zoom: 7,
      options: {
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        scaleControl: false,
        zoomControl: true,
        styles: [
          {
            featureType: "water",
            stylers: [
              {
                visibility: "on",
              },
              {
                color: "#b5cbe4",
              },
            ],
          },
          {
            featureType: "landscape",
            stylers: [
              {
                color: "#efefef",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [
              {
                color: "#83a5b0",
              },
            ],
          },
          {
            featureType: "road.arterial",
            elementType: "geometry",
            stylers: [
              {
                color: "#bdcdd3",
              },
            ],
          },
          {
            featureType: "road.local",
            elementType: "geometry",
            stylers: [
              {
                color: "#ffffff",
              },
            ],
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [
              {
                color: "#e3eed3",
              },
            ],
          },
          {
            featureType: "administrative",
            stylers: [
              {
                visibility: "on",
              },
              {
                lightness: 33,
              },
            ],
          },
          {
            featureType: "road",
          },
          {
            featureType: "poi.park",
            elementType: "labels",
            stylers: [
              {
                visibility: "on",
              },
              {
                lightness: 20,
              },
            ],
          },
          {},
          {
            featureType: "road",
            stylers: [
              {
                lightness: 20,
              },
            ],
          },
        ],
      },
    },
    location_rules: {},
    location_types: [],
    countries: [],
    timezones: [],
    location: {},
    enableTimer: false,
    tagToAdd: "",
    timer: null,
    rules: {
      required: (value) => !!value || "Required.",
    },
    changed: false,

  }),
  mounted() {
    this.getLocation(this.$route.params.uuid);
    this.getLocationTypes();
    this.getCountries();
    this.getTags();
    this.getTimezones();
  },
  watch: {
    getSelectedLocation: {
      handler() {
        this.location = Object.assign({}, this.getSelectedLocation);
        // Ensure global_timezone_enabled is correctly set
        if (typeof this.location.global_timezone_enabled === 'undefined') {
          this.location.global_timezone_enabled = true;
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({"getSelectedLocation": "locations/getSelectedLocation", "tags": "tags/tags", "getDisplayId": "getLocationDisplayId" }),
    google: gmapApi,
  },

  methods: {
    ...mapActions({"getLocation": "locations/getLocationByUUID", "getTags": "tags/getTags", "addTag": "tags/addTag" }),
    getTimezones() {
      // Retrieve the list of supported time zones
      const timeZoneIdentifiers = Intl.supportedValuesOf('timeZone');
      // Map the array of strings to an array of objects with 'name' and 'code' properties
      this.timezones = timeZoneIdentifiers.map((tz) => ({
        name: tz,
        code: tz,
      }));
    },
    editLocation() {
      this.loading = true;
      LocationsApi.editLocation(this.location.space_uuid, this.location.uuid, {
        name: this.location.name,
        description: this.location.description,
        display_id: this.location.display_id,
        zip_code: this.location.zip_code,
        country_code: this.location.country_code,
        city: this.location.city,
        address: this.location.address,
        latitude: this.location.latitude,
        longitude: this.location.longitude,
        tag_uuids: this.location.tag_uuids,
        timezone: this.location.timezone,
        global_timezone_enabled: this.location.global_timezone_enabled, // Include this line
      })
      .then((response) => {
        if (response.status === 200) {
              this.$store.commit("updateAlert", {
                type: "success",
                shown: true,
                message: `Location ${this.location.name} updated successfully.`,
              });
              this.$store.commit("locations/UPDATE_LOCATION", response.data);
              this.$store.commit("locations/SET_SELECTED_LOCATION", response.data);
              this.$store.commit("locations/SET_FLYOUT", {
                editLocation: false,
                editDevice: false,
              });
              this.$router.go(-1)
              this.$store.commit("SET_LOCATION_DISPLAY_ID", "");
              this.loading = false; 
            }
      })
      .catch((error) => {
        // Handle errors
        this.loading = false;
        console.error(error);
      });
    },
    onAddTag() {
      this.addTag(this.tagToAdd).then(response => {
        console.log(response);
      }).catch((error) => {
        console.log(error);
      })
    },
    onTagChange(tag) {
      this.tagToAdd = tag;
    },
    updateSearch() {
      if (this.enableTimer) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.findPlaces();
        }, 400);
      }
    },
    findPlaces() {
      let query = "";
      if (this.location.country_code) {
        query += `${this.location.country_code}`;
      }
      if (this.location.city) {
        query += `+${this.location.city.split(" ").join("+")}`;
      }
      if (this.location.address) {
        query += `+${this.location.address.split(" ").join("+")}`;
      }
      let that = this;
      this.$refs.map.$mapPromise.then((map) => {
        let service = new this.google.maps.places.PlacesService(map);
        service.findPlaceFromQuery(
            {
              query: query,
              fields: ["name", "geometry"],
            },
            function (results) {
              if (results) {
                that.updateMarkerLocation(
                    results[0].geometry.location.lat(),
                    results[0].geometry.location.lng()
                );
              }
            }
        );
      });
    },
    updateMarkerLocation(lat, lng) {
      this.location.latitude = lat;
      this.location.longitude = lng;
    },
    updateMarker(e) {
      this.changed = true;
      this.location.latitude = e.latLng.lat();
      this.location.longitude = e.latLng.lng();
    },
    getCountries() {
      LocationsApi.getCountries()
          .then((response) => {
            this.countries = response.data;
          })
          .catch((err) => {
            this.$store.commit("updateAlert", {
              type: "error",
              shown: true,
              message: `Failed to get countries. Check console.`,
            });
            console.log(err);
          });
    },
    closeForm(){
      this.$router.go(-1)
  },
  changeInput() {
      let inputData = this.location;
      let currentData = this.getSelectedLocation;

      // Check if timezone has changed
      if (inputData.timezone !== currentData.timezone) {
        this.changed = true;
        this.location.global_timezone_enabled = false; // Disable global timezone
      } else {
        // If other fields have changed
        if (
          inputData.name !== currentData.name ||
          inputData.description !== currentData.description ||
          inputData.address !== currentData.address ||
          inputData.country_code !== currentData.country_code ||
          inputData.city !== currentData.city ||
          inputData.zip_code !== currentData.zip_code ||
          inputData.tag_uuids !== currentData.tag_uuids ||
          inputData.display_id !== currentData.display_id
        ) {
          this.changed = true;
        } else {
          this.changed = false;
        }
      }
    },
    getLocationTypes() {
      LocationsApi.getTypes()
          .then((response) => {
            this.location_types = response.data;
            this.location_types.forEach((type) => {
              switch (type.name) {
                case "Office Building":
                  this.location_rules[type.uuid] = {};
                  this.location_rules[type.uuid].country = [this.rules.required];
                  this.location_rules[type.uuid].city = [this.rules.required];
                  this.location_rules[type.uuid].address = [this.rules.required];
                  this.location_rules[type.uuid].zip_code = [this.rules.required];
                  break;
                case "Single Office":
                  this.location_rules[type.uuid] = {};
                  this.location_rules[type.uuid].country = [this.rules.required];
                  this.location_rules[type.uuid].city = [this.rules.required];
                  this.location_rules[type.uuid].address = [this.rules.required];
                  this.location_rules[type.uuid].zip_code = [this.rules.required];
                  break;
                case "Shop":
                  this.location_rules[type.uuid] = {};
                  this.location_rules[type.uuid].country = [this.rules.required];
                  this.location_rules[type.uuid].city = [this.rules.required];
                  this.location_rules[type.uuid].address = [this.rules.required];
                  this.location_rules[type.uuid].zip_code = [this.rules.required];
                  break;
                case "Factory":
                  this.location_rules[type.uuid] = {};
                  this.location_rules[type.uuid].country = [this.rules.required];
                  this.location_rules[type.uuid].city = [this.rules.required];
                  this.location_rules[type.uuid].address = [this.rules.required];
                  this.location_rules[type.uuid].zip_code = [this.rules.required];
                  break;
                case "Warehouse":
                  this.location_rules[type.uuid] = {};
                  this.location_rules[type.uuid].country = [this.rules.required];
                  this.location_rules[type.uuid].city = [this.rules.required];
                  this.location_rules[type.uuid].address = [this.rules.required];
                  this.location_rules[type.uuid].zip_code = [this.rules.required];
                  break;
                case "Home":
                  this.location_rules[type.uuid] = {};
                  this.location_rules[type.uuid].country = [this.rules.required];
                  this.location_rules[type.uuid].city = [this.rules.required];
                  break;
                case "Apartment":
                  this.location_rules[type.uuid] = {};
                  this.location_rules[type.uuid].country = [this.rules.required];
                  this.location_rules[type.uuid].city = [this.rules.required];
                  break;
                case "Event Venue":
                  this.location_rules[type.uuid] = {};
                  this.location_rules[type.uuid].country = [this.rules.required];
                  this.location_rules[type.uuid].city = [this.rules.required];
                  this.location_rules[type.uuid].address = [this.rules.required];
                  this.location_rules[type.uuid].zip_code = [this.rules.required];
                  break;
                case "Mall":
                  this.location_rules[type.uuid] = {};
                  this.location_rules[type.uuid].country = [this.rules.required];
                  this.location_rules[type.uuid].city = [this.rules.required];
                  this.location_rules[type.uuid].address = [this.rules.required];
                  this.location_rules[type.uuid].zip_code = [this.rules.required];
                  break;
                case "Land":
                  this.location_rules[type.uuid] = {};
                  this.location_rules[type.uuid].country = [this.rules.required];
                  this.location_rules[type.uuid].city = [this.rules.required];
                  break;
                case "Mobile":
                  this.location_rules[type.uuid] = {};
                  return;
                case "Other":
                  this.location_rules[type.uuid] = {};
                  break;
                default:
                  return;
              }
              this.location = Object.assign({}, this.getSelectedLocation);
              this.location.latitude = parseInt(this.location.latitude, 10);
              this.location.longitude = parseInt(this.location.longitude, 10);

              this.findPlaces();
              this.enableTimer = true;
            });
          })
          .catch((err) => {
            this.$store.commit("updateAlert", {
              type: "error",
              shown: true,
              message: `Failed to get location types. Check console.`,
            });
            console.log(err);
          });
    },
  
  },

};
</script>

<style scoped lang="scss">
.add-device-btn {
  width: 120px;
}

.bottom-navigation-edit {
  position: absolute;
  bottom: 0 !important;
  left: 0;
  right: 0;
  margin: 0;
  box-shadow: 0 -4px 25px 0 rgba(0, 0, 0, 0.13);
 
}

::v-deep {
  .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    background-color: var(--v-babyBlueish-base) !important;
    color: white !important;
  }
}
</style>
