import Vue from 'vue';
import VueRouter from 'vue-router';
import Assets from '../pages/Assets';
import Monitor from '../pages/Monitor';
import Config from '../pages/Config';
import WorkingTime from '../pages/WorkingTime';
import AccountsWrapper from '../pages/Accounts';
import Accounts from '../views/Accounts';
import JSONPreview from '../components/Applications/JSONPreview.vue';
import RunnerPreview from '../components/Applications/RunnerPreview.vue';
import FlyoutMenu from '../components/FlyoutMenu';
import {TokenStorage} from "@/services/TokenStorage";
import logger from "@/plugins/logger";
import MonitorDetailView from "../components/Monitoring/MonitorDetailView"






const Locations = () => import('../views/Locations');
const Devices = () => import('../views/Devices');
const Groups = () => import('../views/Groups');
const Register = () => import('../views/Register');
const Applications = () => import('../views/Applications');
const WorkingTimeVue = () => import('../views/WorkingTime');
const Runners = () => import('../views/Runners');
const Invite = () => import('../views/Invite');
const Update = () => import('../views/Update');
const UpdateForgotPassword = () => import('../views/UpdateForgotPassword');
const Login = () => import('../views/Login');
const Success = () => import('../views/Success');
const ForgotPassword = () => import('../views/ForgotPassword');
const NewPassword = () => import('../views/NewPassword');
const Performance = ()=>import('../views/Performance');
const Activity = ()=>import('../views/Activity');
const Logs = () => import('../views/Logs');
const Events = () => import('../views/Events');
Vue.use(VueRouter);

const routes = [
  {
    path: '/account-registration',
    name: 'Register',
    component: Register
  },
  {
    path: '/space-join',
    name: 'Invite',
    component: Invite
  },
  {
    path: '/account-password-update',
    name: 'UpdatePassword',
    component: Update
  },
  {
    path: '/account-password-reset',
    name: 'UpdateForgotPassword',
    component: UpdateForgotPassword
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/update-account',
    name: 'Success',
    component: Success
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/change-password',
    name: 'NewPassword',
    component: NewPassword
  },
  {
    path: '/accounts',
    name: '',
    component: AccountsWrapper,
    children: [
      {
        path: '/',
        name: 'accounts',
        component: Accounts,
        children: [
          {
            path: ':action',
            name: 'accounts_action',
            component: FlyoutMenu
          },
        ]

      },
      
    ]
  },
  {
    path: '/',
    redirect: 'devices',
    name: 'Assets',
    component: Assets,
    children: [
      {
        path: '/locations',
        name: 'locations',
        component: Locations,
        children: [
          {
            path: ':action',
            name: 'location_action',
            component: FlyoutMenu,
          },
          {
            path: ':location_type/:action/:uuid/:tab',
            name: 'location',
            component: FlyoutMenu,
          },
          {
            path: ':action',
            name: 'device_action',
            component: FlyoutMenu,
            props: true
          },
          {
            path: ':device_type/:action/:space_uuid/:uuid/:tab',
            name: 'device_location',
            component: FlyoutMenu,
            props: true,
          },
          {
            path: 'global',
            name: 'working_time_action',
            component: FlyoutMenu,
            props: true,

          }
        ],
      },
      {
        path: '/devices',
        name: 'devices',
        component: Devices,
        props: true,
        children: [
          {
            path: ':action',
            name: 'device_action',
            component: FlyoutMenu,
            props: true
          },
          {
            path: ':device_type/:action/:space_uuid/:uuid/:tab',
            name: 'device',
            component: FlyoutMenu,
            props: true,
          },
        ],
      },
      {
        path: "/groups",
        name: "groups",
        component: Groups,
        children: [
          {
            path: ':action',
            name: 'group_action',
            component: FlyoutMenu,
          },
          {
            path: ':group_type/:action/:space_uuid/:uuid/:tab',
            name: 'group',
            component: FlyoutMenu,
            props: true,
          },
        ]
      }
    ],
  },
  {
    path: '/',
    redirect: 'activity',
    name: 'Monitor',
    component: Monitor,
    children: [

      {
        path: '/activity',
        name: 'activity',
        component: Activity,
         children: [
          {
            path: ':action',
            name: 'device_action',
            component: FlyoutMenu,
            props: true
          },
          {
            path: ':device_type/:action/:space_uuid/:uuid/:tab',
            name: 'activity',
            component: FlyoutMenu,
            props: true,
          },

        ],
      },
      {
        path: '/performance',
        name: 'performance',
        component: Performance,
        children: [
          {
            path: ':action',
            name: 'device_action',
            component: FlyoutMenu,
            props: true
          },
          {
            path: ':monitoring_type/:action/:space_uuid/:uuid/:tab',
            name: 'monitoring',
            component: MonitorDetailView,
            props: true,
          },

        ],
      },
      {
        path: '/events',
        name: 'events',
        component: Events,
      },
      {
        path: '/logs',
        name: 'logs',
        component: Logs,
      }
    ]

  },
  {
    path: '/',
    redirect: 'applications',
    name: 'Config',
    component: Config,
    children: [
      {
        path: '/applications',
        name: 'Applications',
        component: Applications,
        children: [
          {
            path: ':appId',
            name: 'ApplicationDetails',
            component: JSONPreview, // Assuming JSONPreview is set up to function as a routed component
            props: true
          }
        ]
      },
      {
        path: '/runners',
        name: 'Runners',
        component: Runners,
        children: [
          {
            path: ':runnerId',
            name: 'RunnerDetails',
            component: RunnerPreview, // Assuming JSONPreview is set up to function as a routed component
            props: true
          }
        ]
      },
    ]

  },
  {
    path: '/',
    redirect: 'working_time',
    name: 'WorkingTime',
    component: WorkingTime,
    children: [
      {
        path: 'working_time',
        name: 'WorkingTime',
        component: WorkingTimeVue,
        props: true
      }
    ]
  }
];

const router = new VueRouter({
  mode: process.env.NODE_ENV === 'production' ? 'history' : 'hash',
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    "/",
    "/account-registration",
    "/account-password-update",
    "/account-password-reset",
    "/space-join",
    "/login",
    "/forgot-password",
    "/change-password",
    "/update-account",
  ];
  const authRequired = !publicPages.includes(to.path);
  const isAuthenticated = TokenStorage.isAuthenticated();

  if (authRequired && !isAuthenticated) {
    logger.error("Unauthorized", "", "IAM");
    return next("/login");
  }

  // Ensure users are redirected correctly after login
  if (to.path === '/' && isAuthenticated) {
    return next('/devices'); // Redirect authenticated users to devices
  }

  next();
});

export default router;