<template>
  <div>
    <v-card elevation="0" outlined class="rounded-lg pb-3 pt-0 mt-0">
      <v-row class="align-center mb-0 pb-0">
        <v-col  cols="12" md="4" class="d-flex align-center pb-0 justify-space-between">
          <span class="pr-2 pl-2 ml-4">{{ formattedDay }}</span>
          <v-switch
            :disabled="this.$route.params.tab === 'global'"
            class="ml-auto"
            v-model="isDisabled"
            @change="toggleDayOff"
          ></v-switch>
        </v-col>
        <!-- Day Off Label -->
        <v-col cols="12" class="pb-0 mb-0" md="6" v-if="!isDisabled && entries.length === 0">
          <v-text-field
            height="40"
            class="pb-0 mb-0"
            outlined
            value="Day Off"
            disabled
            readonly
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <!-- Unified Entry Handling -->
        <template v-else>
          <v-row v-for="(entry, index) in entries" :key="entry.id || index" class="align-center" :class="{ 'offset-md-4 mt-0 mb-4': index > 0 }">
            <v-col cols="12" md="auto" class="pb-0" :class="{ 'pl-0': index > 0, 'pb-2 pt-2': index > 1 }">
              <v-text-field
                outlined
                v-model="entry.start"
                label="hh:mm:ss"
                :rules="timeRules"
                hide-details="auto"
                dense
                :error-messages="getErrorMessage(index)"
                :disabled="isGloballyDisabled"
                @input="emitUpdate"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="auto" class="pb-0" :class="{ 'pl-0 ml-3': index > 0, 'pb-2 pt-2': index > 1 }">
              <v-text-field
                outlined
                v-model="entry.end"
                label="hh:mm:ss"
                :rules="timeRules"
                hide-details="auto"
                dense
                :error-messages="getErrorMessage(index)"
                :disabled="isGloballyDisabled"
                @input="emitUpdate"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="1" class="pb-0 pl-0 pr-0 ml-0" :class=" {'pt-0': index > 1}">
              <v-btn 
                outlined  
                height="40" 
                width="40"  
                tile 
                icon 
                @click="deleteEntry(index)" 
                v-show="index > 0 || entries.length > 1"
                :class="{'btn-light-trash': !isDarkTheme, 'btn-dark-trash': isDarkTheme  }"  
                :disabled="isGloballyDisabled">
                <v-icon>{{ icon.mdiTrash }}</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" md="1" class="pb-0 pl-0 ml-0">
            <v-btn
              v-show="index === 0"
              tile
              icon
              :class="{'btn-light': !isDarkTheme, 'btn-dark': isDarkTheme}"
              width="40"
              height="40"
              @click="addEntry"
              :disabled="isGloballyDisabled"
              style="border-radius: 4px;"
            >
              <v-icon>{{ icon.mdiPlus }}</v-icon>
            </v-btn>
          </v-col>
          </v-row>
        </template>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mdiPlus, mdiTrashCanOutline } from '@mdi/js';

export default {
  props: ['data', 'day', 'messageError'],
  data() {
    return {
      isDisabled: this.data.length > 0,
      entries: this.data.length ? this.data.map(e => ({ ...e, error: '' })) : [],
      icon: {
        mdiTrash: mdiTrashCanOutline,
        mdiPlus: mdiPlus
      },
    };
  },
  computed: {
    formattedDay() {
      return this.day.replace('working_time_', '').charAt(0).toUpperCase() + this.day.replace('working_time_', '').slice(1);
    },
    timeRules() {
    return [
      value => /^(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d$/.test(value) || 'Time must be in hh:mm:ss format',
      () => !this.messageError || this.messageError
    ];
  },
    isGloballyDisabled() {
      return !this.isDisabled || this.$route.params.tab === 'global';
    },
    isDarkTheme() {
      return this.$vuetify.theme.dark || false;
    },
  },
  methods: {
    toggleDayOff() {
  if (this.isDisabled) {
    if (this.entries.length === 0) {
      this.entries.push({ start: '', end: '', error: '' });
    }
  } else {
    this.entries = [];
  }
  this.emitUpdate();
},
    addEntry() {
      this.entries.push({ start: '', end: '', error: '' });
      this.emitUpdate();
    },
    deleteEntry(index) {
      this.entries.splice(index, 1);
      this.emitUpdate();
    },
    emitUpdate() {
      this.$emit('update-entries', { day: this.day, entries: this.entries });
    },
    // In Child's <script>
  getErrorMessage(index) {
    const dayErrors = this.messageError[this.day];
    if (dayErrors && dayErrors[index]) {
      return [dayErrors[index]];
    }
    return '';
  },
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.entries = newVal.length ? newVal.map(e => ({ ...e, error: '' })) : [];
        this.isDisabled = newVal.length > 0;
      }
    }
  }
};
</script>
<style scoped>
.btn-light-trash {
  border-color: var(--v-lineColor-base);
  border-radius: 4px;
}
.btn-dark-trash {
  border-color: var(--v-borderColor-base);
  border-radius: 4px;
}

.btn-light {
  background-color: #F5F5F5; /* Light background for light theme */ /* Suitable border color for light theme */

}

/* Dark theme styles */
.btn-dark {
  background-color: #424242; /* Darker background for dark theme */
}
</style>